@import './variables.less';

body,
button {
  letter-spacing: 0.4px;
}

.gx-main-content-wrapper,
.gx-container {
  min-width: 750px;
}

.gx-main-content-wrapper {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.ant-layout > .gx-layout-content {
  overflow-x: initial;
  overflow-y: scroll;
}

[class^='caf-'],
[class*=' caf-'] {
  font-size: 16px;
  line-height: 1;
}

[class^='caf-']:before,
[class*=' caf-']:before {
  margin: 0 !important;
}

.ant-switch {
  i[class^='caf-'],
  i[class*=' caf-'] {
    font-size: 15px;
    display: flex;
  }
}

.ant-modal-body {
  padding: 30px 30px 20px;
}

.ant-notification {
  z-index: 9999;
}

.custom-search-input,
.custom-input {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid @gray-color-3;
  box-shadow: none !important;
  padding-top: 0;
  padding-bottom: 0;

  input {
    background-color: transparent;
    border-radius: 0;
    height: 35px;
  }

  i {
    font-size: 17px;
    color: @text-dark;
  }

  &:hover {
    border-bottom-color: @gray-color-3;
  }

  &:focus-within {
    border-bottom-color: @primary-color;
  }

  .ant-input-prefix {
    margin-right: 10px;
  }
}

.custom-search-input {
  min-width: 230px;
}

.custom-input {
  padding: 10px 0px;
}

.custom-label {
  label {
    color: @text-dark !important;
    font-size: 15px !important;
    font-weight: 500 !important;
  }
}

.custom-select-input {
  .ant-select-selector {
    border-radius: 0 !important;
    border-bottom: 1px solid @gray-color-3 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .ant-select-selection-item {
    padding-right: 24px !important;
  }
}

.custom-table-dark {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    color: #000;
  }
}

.custom-notification {
  padding: 16px;

  .ant-notification-notice-message {
    font-weight: 500;
    line-height: 20px;
  }

  .ant-notification-notice-description {
    margin-top: 2px;
  }

  .ant-notification-notice-icon {
    margin-top: 2px;
  }
}

.custom-pop-confirm {
  .ant-popover-inner {
    .ant-popover-message {
      .anticon {
        display: none;
      }

      .ant-popover-message-title {
        margin-bottom: 5px;
        padding: 0px 5px !important;

        font-size: 15px;
      }
    }
    .ant-popover-buttons {
      display: flex;
      justify-content: center;
    }
  }
}

.custom-loading-container {
  display: flex;
  justify-content: center;

  width: 100%;
  height: 100%;

  opacity: 0.8;
  background: @body-background;

  position: absolute;
  z-index: 999;
}

.uppercase-form {
  .custom-input {
    text-transform: uppercase;
  }
}

.page-title {
  .title {
    font-size: 30px;
    margin-bottom: 0;
    font-weight: 900;
  }
  .subtitle {
    color: @text-color-secondary;
  }
  .subtitle-break {
    display: block;
    color: @text-color-secondary;
  }
}

.text-dark {
  color: @text-dark !important;
}

.text-default {
  color: @text-color !important;
}

.text-primary {
  color: @primary-color;
}

.text-secondary {
  color: @text-color-secondary !important;
}

.text-success {
  color: @success-color;
}

.text-link {
  color: @info-color !important;
}

.text-danger {
  color: @danger-color !important;
}

.text-default-form {
  span {
    color: @text-color;
  }
}

.pdd-horizontal-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.reset-btn {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;

  text-align: inherit !important;
  line-height: inherit !important;
  white-space: inherit !important;
  font-weight: inherit !important;
}

.reset-pdd {
  padding-left: 0px;
  padding-right: 0px;
}

.reset-collapse {
  .ant-collapse-header {
    display: none;
  }
  .ant-collapse-content-box {
    padding: 0;
  }
}

.reset-horizon-mrg {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.reset-vertical-mrg {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.select-down {
  font-size: 22px !important;
  color: @text-dark !important;
}

input:read-only {
  cursor: default;

  &:focus {
    box-shadow: none;
  }
}

.btn-hover-default {
  &:hover {
    background-color: #f2f2f2 !important;
  }
}

.fd-row {
  flex-direction: row !important;
}

.no-error {
  .ant-form-item-explain {
    animation: none !important;
    display: none !important;
    opacity: 0 !important;
    visibility: hidden !important;
  }
}

.caf-modal {
  .ant-modal-body {
    padding: 30px 40px;
  }

  .modal-header {
    margin-bottom: 30px;
  }

  .modal-title {
    font-size: 26px;
    margin-bottom: 0.3em;
    font-weight: 900;
  }

  .modal-subtitle {
    font-size: 14px;
    font-weight: 400;
    color: @text-color-secondary;
  }

  &.offset-timeline {
    right: 370px;
  }
}

.btn-more-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 30px;
  width: 30px;

  border-radius: 50%;

  cursor: pointer;

  i {
    color: @text-dark;
  }

  &.btn-more-icon-hover {
    &:hover {
      background-color: darken(@gray-color-4, 8%);
    }
  }

  &:hover {
    background-color: darken(@gray-color-4, 3%);
  }
}

a.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.ant-btn.btn-cancel {
  color: @text-dark;
  border-color: @border-color;

  &:hover {
    background-color: @gray-color-4;
    border-color: @border-color;
  }
}

.ant-tag {
  i {
    font-size: 12px;

    &:first-child {
      padding-left: 0;
      padding-right: 6px;
      margin-left: -1px;
      margin-right: 0;
    }
    &:last-child,
    &.icon-right {
      order: 10;
      padding-left: 6px;
      padding-right: 0;
      margin-left: 0;
      margin-right: -1px;
    }
  }
}

.multiselect-filters {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 0;

  .ant-select-item-option {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ant-select-item-option-content {
    border-bottom: 1px solid @gray-color-4;
    padding-right: 20px;
  }

  .ant-select-item-option-state {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);

    > span {
      font-size: 13px;
    }
  }

  .ant-select-item-empty {
    font-size: 13px;
    text-align: center;
    font-style: italic;
    padding: 25px 32px;
    word-break: break-word;
  }

  .multiselect-option {
    display: flex;
    flex-direction: column;
    padding: 12px 0;

    .option-title {
      font-size: 13px;
      font-weight: 700;
      color: @text-dark;
      margin-bottom: 4px;
      line-height: 1.2;
    }

    .option-subtitle {
      font-size: 11px;
      font-weight: 400;
      font-style: italic;
      color: @text-dark;
      line-height: 1.2;
    }
  }
}

.border-left {
  border-left: 1px solid @gray-color-3;
}

.border-right {
  border-right: 1px solid @gray-color-3;
}

.ant-checkbox-wrapper {
  display: flex;
  align-items: center;
  color: @text-dark;
  font-weight: 300;
  font-size: 13px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: @primary-color;
}

.ant-checkbox-wrapper {
  margin-left: 0;
}

.ant-checkbox {
  top: -0.04em;
}

.ant-checkbox-inner {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 6px;
  background-color: transparent;
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    &::after {
      font-family: 'caf-icons';
      content: '\e813';
      top: 1px;
      left: 2px;
      font-size: 15px;
      border: none;
      color: @gray-color-4;
      transform: scale(1);
    }
  }
}

form.disabled {
  .ant-checkbox-inner {
    background-color: @white-color;
  }

  input:disabled,
  textarea:disabled,
  .ant-select-selector,
  .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
    background-color: @body-background;
    color: @text-dark;

    &::placeholder {
      color: @text-dark;
    }
  }

  .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
    &::after {
      color: rgba(0, 0, 0, 0.2);
    }
  }
}

i.loading-icon {
  animation: rotate 1.5s linear infinite;

  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
}

.ant-select-item.ant-select-item-group {
  color: @gray-color-1;
}
