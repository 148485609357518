@import '../../styles/variables.less';

#user-info-component {
  .user-dropdown-trigger {
    color: @white-color;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.6px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    cursor: pointer;

    i {
      font-size: 18px;
      margin-left: 4px;
      opacity: 0.8;
      transition: opacity 0.2s;
    }

    .ant-avatar {
      border: 1px solid @border-color;
    }

    &:hover {
      i {
        opacity: 1;
      }
    }
  }
}

.user-dropdown {
  min-width: 350px !important;
  width: 350px !important;
  top: 50px !important;

  padding-top: 8px;

  .user-profile {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 14px 20px;
    cursor: pointer;
    transition: background 0.2s;

    &:hover {
      background: #eeeeee;
    }

    > div {
      position: relative;

      > i {
        position: absolute;
        bottom: 2px;
        right: 4px;
        font-size: 14px;
        background: #eeeeee;
        padding: 5px;
        border-radius: 50%;
        cursor: pointer;
        transition: filter 0.2s;

        &:hover {
          filter: brightness(0.9);
        }
      }
    }

    section {
      h3 {
        font-size: 18px;
        font-weight: 600;
        text-align: left;
        margin-bottom: 5px;
      }

      small {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
      }
    }
  }

  .nav-link-users-container {
    height: auto !important;
    padding: 6px 10px !important;
  }

  .nav-link-users {
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: 10px;

    span {
      color: @text-dark;
      font-size: 15px;
      font-weight: 500;
    }

    i {
      margin-right: 12px;
      color: @gray-color-1;
      font-size: 18px;
    }

    .caf-ic_person:before {
      font-weight: 500 !important;
    }
  }

  .btn-logout {
    min-width: 170px;
    border-radius: 8px !important;
    height: 40px;
    font-weight: 400;
    color: @text-dark;
    border-color: @border-color;
    text-shadow: none;

    &:hover,
    &:active,
    &:focus {
      background-color: lighten(@border-color, 3%);
    }
  }

  .ant-divider {
    border-top-color: @border-color;
  }

  .privacy-policies-terms-of-use {
    padding: 16px 0 12px 0;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 18px;

      a {
        font-size: 12px;
        color: @text-dark;
        transition: color 0.2s;

        &:hover {
          color: @primary-color;
        }

        &:last-child {
          padding-left: 20px;
          margin-bottom: 2px;
          position: relative;
        }

        &:last-child::before {
          content: '';
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: @text-dark;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent !important;
}
