#unauthorized-component {
  #unauthorized-content {
    width: 100%;
    height: 100%;
    padding: 0 12%;

    .title {
      text-align: center;
      color: #2f2e41;
      opacity: 0.75;
      font-size: 34px;
    }

    .subtitle {
      margin: 0;
      color: #2f2e41;
      opacity: 0.75;
    }

    img {
      width: 68%;
    }
  }
}
