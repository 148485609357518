@import '../../styles/variables.less';

#header-component {
  &.gx-header-horizontal {
    transition: all 0.2s ease-in-out;

    &::before {
      display: none !important;
    }
  }

  &.gx-inside-header-horizontal {
    .ant-menu-item {
      padding: 0 10px;
    }
  }

  .gx-container {
    max-width: 1280px;
  }

  .gx-logo {
    margin-right: 45px;
  }

  .gx-header-notifications {
    padding: 0 10px;
  }

  .gx-header-notifications li:first-of-type {
    margin-left: 0;
  }

  .nav-link {
    padding: 3px;
    border-bottom: 3px solid transparent;

    color: @header-text-color;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.6px;

    opacity: 0.8;

    &:hover {
      opacity: 1;
    }

    &:before {
      bottom: -2px;
    }

    &.active {
      border-bottom: 2px solid @menu-active-color;
      opacity: 1;
    }

    transition: opacity 0.2s;
  }

  .icon-color {
    color: @primary-color;
  }

  header {
    background-color: @secondary-color;
  }

  .header-hide {
    top: -100px;
    max-height: 0;
    transition: all 0.3s linear;
  }

  .header-show {
    top: 0;
    max-height: 200px;
    transition: all 0.3s linear;
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
    margin: 0;
  }

  .header-logo {
    min-height: 56px;

    .icon-container {
      height: 28px;
      width: 28px;
      margin-right: 8px;
      border-radius: 4px;
    }

    h1 {
      margin-bottom: 0;
      color: @white-color;
      font-size: 22px;
      font-weight: 600;
    }

    i {
      color: @white-color;
      font-size: 22px;
    }
  }

  .logo-other-domain {
    height: 32px !important;
  }
}
