svg.caf-loader {
  overflow: visible;
  width: 100px;
  height: 150px;

  path {
    stroke: url(#gradient);
    stroke-width: 2px;
    stroke-linecap: round;
    fill: none;
    stroke-dasharray: 0 157px;
    stroke-dashoffset: 0;
    animation: escalade 3s cubic-bezier(.19,.41,.84,.6) infinite alternate;
  }
}

@keyframes slide {
  0% {
    transform: translateY(-50px);
  }

  100% {
    transform: translateY(50px);
  }
}

@keyframes escalade {
  0% {
    stroke-dasharray: 0px 150px;
    opacity: 0;
  }

  40% {
    stroke-dasharray: 170px 150px;
    opacity: 1;
  }

  60% {
    stroke-dasharray: 220px 150px;
      opacity: 1;
    }

  100% {
    stroke-dasharray: 280px 150px;
    opacity: 1;
  }
}
