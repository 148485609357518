@import '../../../../../styles/variables.less';

#change-password-modal-content{
  width: 100%;
  flex:1;
  display: flex;
  flex-direction: column;

  .loading-spin-container{
      height: 358px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0;
  }

  .modal-action-buttons-cp{
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
  }
  ul {
    padding: 0;

    li {
      display: flex;
      align-items: center;

      list-style: none;

      font-size: 15px;
      font-weight: 300;

      i {
        margin-right: 5px;

        &.caf-ic_minimize {
          font-size: 10px;
        }

        &.caf-ic_checkmark {
          color: @primary-color;
        }

        &.caf-ic_close {
          color: @danger-color;
        }
      }
    }
    li + li {
      margin-top: 5px;
    }
  }
}
