@import '../../../styles/variables.less';

#consumptions-component {
  .ant-tabs-nav::before {
    border-bottom: none !important;
  }

  .ant-tabs-ink-bar {
    background-color: @primary-color !important;
  }

  .ant-table-content {
    .ant-table-cell {
      padding-top: 15px;
      padding-bottom: 15px;
      color: @text-dark;

      .product-name {
        font-size: 16px;
        font-weight: bold;
      }

      .product-data-row {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        font-size: 14px;

        .total-executions {
          margin-left: 3rem;
        }

        .total-price {
          font-weight: bold;
          border: 0px !important;
        }
      }
    }
  }

  .progressBarHeader {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    font-weight: 700;
    color: @text-dark;
  }

  .progressBarContent {
    height: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    animation: showProgressBar 0.5s ease;
    border-radius: 10px;
    overflow: hidden;
  }

  @keyframes showProgressBar {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  }

  #invoice-card {
    margin: 40px 0px 0px 40px !important;
    width: 100%;
    max-height: 285px;

    .ant-row {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }

    .invoice-title {
      margin-bottom: 20px !important;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.5px;
      color: @text-dark;
    }

    .invoice-item {
      font-size: 14px;
      height: 1.6rem;
      border-bottom: 1px solid #ccc;
      margin-top: 10px;
    }

    .invoice-button {
      margin-top: 30px !important;

      i {
        margin-left: 5px;
      }
    }
  }
}
