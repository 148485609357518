@import '../../../../styles/variables.less';

#customer-wrapper-component {
  .tabs {
    margin-bottom: 40px;

    a {
      color: @text-dark;
      font-size: 16px;
      padding-bottom: 8px;
      border-bottom: 2px solid transparent;
      font-weight: 500;

      & + a {
        margin-left: 32px;
      }
    }

    .active {
      color: @text-dark !important;
      border-bottom: 2px solid @primary-color !important;
    }

    i[class^='caf-'],
    i[class*=' caf-'] {
      font-size: 16px;
      margin-right: 8px;
    }
  }

  .timeline-button-wrapper {
    height: 50px;
  }

  .timeline-button {
    position: absolute;
    z-index: 50;
    bottom: 0;
    right: 0;
    margin-bottom: 0;
    border-radius: 0;
    border: 0;
    background: @nav-dark-bg;
    color: @white-color;
    width: 300px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: inherit;
    &:hover,
    &:focus {
      background: lighten(@nav-dark-bg, 5%);
      color: @white-color;
    }
  }

  .ant-table-content {
    th.ant-table-cell {
      span.column-title {
        color: @text-dark;
        font-size: 14.5px;
        font-weight: 500;
      }
    }
  }

  .tag-you {
    background-color: @primary-color;
    color: @white-color;
  }
}
