@import '../../../styles/variables.less';

.custom-dropdown {
  padding: 0;
  width: 335px;

  .ant-menu {
    margin: 6px 0px;
  }

  .ant-menu-item {
    display: flex;
    align-items: center;

    height: 40px !important;

    .custom-menu-item {
      display: flex;
      align-items: center;
      width: 300px;

      i {
        color: @primary-color;
        margin-right: 15px;
        font-weight: bolder;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .icon-custom-item {
        font-size: 22px;
        background-color: @inverse-primary-color;
        border-radius: 50%;
        margin-right: 15px;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .icon-custom-item-info-color {
        font-size: 22px;
        background-color: @inverse-info-color;
        border-radius: 50%;
        margin-right: 15px;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      div.infos {
        flex-direction: row;
        width: 240px;

        .ant-typography,
        div.subtitle {
          margin: 0;
          padding: 0;
        }

        .ant-typography {
          display: block;
          font-weight: bold;
          font-size: 16px;
          color: lighten(@text-dark, 8%);
          line-height: 1.5;
          margin-bottom: 2px;
        }

        div.subtitle {
          display: flex;
          color: @text-color;
          line-height: 1;
          max-width: 240px;
          white-space: pre-line;
          word-break: break-word;
          font-weight: lighter;
          line-height: 1.3;
          font-size: 13px;
        }
      }
    }
  }

  .ant-typography {
    color: @black-color;
    margin-bottom: 5px;
  }
}

.custom-dropdown-recurrence {
  .ant-typography {
    margin: 10px 16px;
    display: block;
    color: @text-dark;
    font-weight: 500;
  }

  .ant-menu > .ant-menu-item-divider {
    margin-bottom: 8px;
  }
}

.coming-soon {
  color: red !important;
}
