.private-faceset-card {
  position: relative;
  display: inline-block;

  & + .private-faceset-card {
    margin-left: 20px;
  }

  .similarity {
    position: absolute;
    z-index: 99;
    top: 8px;
    left: 8px;
    background-color: red;
    color: #fff;
    border-radius: 100px;
    padding: 0 7px;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.5;
  }
}
