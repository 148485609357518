@import '../../styles/variables.less';

#management-info-component {
  .management-dropdown-trigger {
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.6px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    cursor: pointer;

    i {
      font-size: 18px;
      margin-left: 4px;
      opacity: 0.8;
      transition: opacity 0.2s;
    }

    .ant-avatar {
      border: 1px solid @border-color;
    }

    &:hover {
      i {
        opacity: 1;
      }
    }
  }
}

.management-dropdown {
  width: 382px;
  top: 50px !important;

  padding-top: 8px;

  .user-profile {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 14px 20px;
    cursor: pointer;
    transition: background 0.2s;

    &:hover {
      background: #eeeeee;
    }

    > div {
      position: relative;

      > i {
        position: absolute;
        bottom: 2px;
        right: 4px;
        font-size: 14px;
        background: #eeeeee;
        padding: 5px;
        border-radius: 50%;
        cursor: pointer;
        transition: filter 0.2s;

        &:hover {
          filter: brightness(0.9);
        }
      }
    }

    section {
      h3 {
        font-size: 18px;
        font-weight: 600;
        text-align: left;
        margin-bottom: 5px;
      }

      small {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
      }
    }
  }

  .nav-link-management-container {
    height: 40px !important;
    padding: 6px 10px !important;
    i {
      color: @primary-color !important;
    }
  }

  .nav-link-management {
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: 10px;
    padding: 3px;
    border-bottom: 3px solid transparent;
    line-height: 22px;

    span {
      color: @text-dark;
      font-size: 16px;
      font-weight: 500;
    }

    i {
      margin-right: 12px;
      color: @gray-color-1;
      font-size: 18px;
    }

    .caf-ic_person:before {
      font-weight: 500 !important;
    }
  }

  .ant-divider {
    border-top-color: @border-color;
    padding: 0;
    margin-top: 5px;
    margin-bottom: 0;
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent !important;
}
