#access-keys-component {
  .custom-page-title {
    flex: 1 !important;
  }

  .android-logo {
    width: 36px !important;
  }

  .ios-logo {
    width: 64px !important;
  }
}
