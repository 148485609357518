@import '../../styles/variables.less';

#password-validations-list {
  span {
    font-size: 14px;
    font-weight: 300;
  }

  ul {
    display: flex;
    flex-wrap: wrap;

    padding: 0;

    li {
      display: flex;
      align-items: center;

      width: 50%;
      list-style: none;

      font-size: 14px;
      font-weight: 300;

      i {
        margin-right: 5px;

        &.caf-ic_minimize {
          font-size: 10px;
        }

        &.caf-ic_checkmark {
          color: @primary-color;
        }

        &.caf-ic_close {
          color: @danger-color;
        }
      }
    }

    li + li {
      margin-top: 5px;
    }
  }
}
