@import '../../../styles/variables.less';

#product-card-component {
  height: 100%;
  min-height: 145px;
  max-width: 400px;

  margin-bottom: 0px;

  overflow: hidden;
  position: relative;

  &.selected {
    margin: -1px;
    background-color: @light-primary-color;
    border: 2px solid @primary-color;
  }

  &.default {
    background-color: @white-color;
    border: 1px solid @border-color;
  }

  .ant-card-body {
    display: flex;
    padding: 18px 18px;
  }

  &.custom-pdd-btm {
    .ant-card-body {
      padding: 18px 18px 10px;
    }
  }

  .icon-container {
    flex-shrink: 0;

    height: 42px;
    width: 42px;

    background-color: @primary-color;
    border-radius: 50%;

    i {
      color: @white-color;
    }
  }
}

#product-to-active-component {
  padding: 24px;
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;

  background-color: rgba(255, 255, 255, 0.8);

  a {
    text-decoration: underline !important;
  }
}
