@import '../../../../../styles/variables.less';

#select-policies{
  .tag-perms {
    color: @primary-color;
    background-color: @inverse-primary-color;
  }

  .ant-collapse-header {
    display: flex;
    padding-right: 15px;
  }

  .ant-collapse {
    &.no-border-bottom {
      -webkit-border-bottom-right-radius: 0px;
      -webkit-border-bottom-left-radius: 0px;
      -moz-border-radius-bottomright: 0px;
      -moz-border-radius-bottomleft: 0px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;

      .ant-collapse-content {
        border-radius: 0px;
      }
    }

    &.no-border-top {
      -webkit-border-top-right-radius: 0px;
      -webkit-border-top-left-radius: 0px;
      -moz-border-radius-topright: 0px;
      -moz-border-radius-topleft: 0px;
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
    }
  }

  .ant-collapse-extra {
    width: 50%;
  }

  .ant-collapse-header-text {
    width: 50%;
  }

  .special-rules {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 20px;

    .ant-checkbox-wrapper {
      flex-direction: row-reverse;
      justify-content: space-between;

      span {
        padding: 0;
      }
    }

  }

  .divider-checkboxes {
    border-top: 1px solid #EEEEEE;
    width: 95%;
    padding: 5px 0px;

    &:nth-child(-n+2) {
      border-top: none;
    }
  }

  .divider-crud{
    padding: 5px 0;

    & + .divider-crud {
      border-top: 1px solid #EEEEEE;
    }
  }
}
